<template>
	<div class="thank-you">
		<div class="page-section" :class="$responsive">
			<success-message :contactName="name" :marketing="marketing">
				hemos recibido tu información.
				<br v-if="!$responsive.mobile">
				Uno de nuestros asesores se pondrá en contacto contigo lo antes posible.
			</success-message>
		</div>
		<faq topic="SignOn"></faq>	
	</div>
</template>

<script>
	import content from "@/content.json";
	import successMessage from "@/components/SuccessMessage.vue";
	import faq from "@/components/FaqCompact.vue";

	export default {
		name: "ThanYou",
		props: {
			name: {
				type: String,
				default: '...'
			}
		},
		components: {
			successMessage,
			faq
		},
		data() {
			return {
				marketing: content.marketing.ourAppsAd
			}
		},
	}
</script>

<style>
	.thank-you .faq-compact.mobile {
		margin-top: 6rem;
	}

	.thank-you .page-section.mobile {
		padding: 1rem;
	}
</style>